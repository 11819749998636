import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { changeEmail } from '../../api/v1/put';
import { domainNameParser } from '../../helpers';

const ChangeEmailPage = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('Changing your email...');

  useEffect(() => {
    const handleChangeEmail = async () => {
      const actionToken = searchParams.get('action_token');
      const redirect = searchParams.get('redirect');

      if (actionToken && redirect) {
        try {
          await changeEmail({
            action_token: actionToken,
          });

          setMessage('Redirecting...');
          window.location.replace(`${domainNameParser(redirect)}/login`);
        } catch (error) {
          setMessage(
            `Oops there is an error! Code: ${error.response.status}, Message: ${error.response.data.message}`,
          );
        }
      }
    };

    handleChangeEmail();
  }, []);

  return (
    <div>
      <span>{message}</span>
    </div>
  );
};

export default ChangeEmailPage;
