/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import Footer from './Footer/Footer';
import { bodyStyles } from './Layout.styles';

const Layout = ({ isPaddingActive, backgroundColor, children }) => (
  <>
    <div css={bodyStyles(isPaddingActive, backgroundColor)}>{children}</div>
    <Footer />
  </>
);

export default Layout;

Layout.propTypes = {
  isPaddingActive: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  backgroundColor: 'BACKGROUND_GREY',
  isPaddingActive: true,
};
