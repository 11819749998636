import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const modalStyles = (isModalShown) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${isModalShown ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 500;
`;

export const overlayStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.PRIMARY_BLACK};
  opacity: 0.25;
`;

export const modalBodyStyles = css`
  z-index: 10;
`;
