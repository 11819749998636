/** @jsxImportSource @emotion/react */
import { Text } from '../../general';
import {
  footerStyles,
  footerContentStyles,
  descriptionStyles,
} from './Footer.styles';

const Footer = () => {
  const getCurrentYear = () => {
    const date = new Date();
    const year = date.getFullYear();

    return year;
  };
  return (
    <div css={footerStyles}>
      <div css={footerContentStyles}>
        <Text styles={descriptionStyles}>
          &copy;
          {` ${getCurrentYear()} hacktiv8. all rights reserved.`}
        </Text>
      </div>
    </div>
  );
};

export default Footer;
