import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const linkStyles = css`
  display: inline-block;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.WHITE};
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;
