/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import colors from '../../../configs/colors';
import { textStyles } from './Text.styles';

const Text = ({
  children,
  styles,
  textTransform,
  color,
}) => (
  <p css={[textStyles({ textTransform, color }), styles]}>{children}</p>
);

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  styles: PropTypes.shape({}),
  textTransform: PropTypes.oneOf([
    'none',
    'lowercase',
    'capitalize',
    'uppercase',
  ]),
  color: PropTypes.string,
};

Text.defaultProps = {
  styles: css``,
  textTransform: 'none',
  color: colors.PRIMARY_BLACK,
};

export default Text;
