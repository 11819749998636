import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const primaryCardStyles = css`
  width: 275px;
  height: 200px;
  padding: 36px 11px;
  border-radius: 16px;
  background-color: ${colors.WHITE};
`;
