import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const buttonStyles = (width, color, inverted) => css`
  width: ${width === 'full' ? '100%' : `${width}px`};
  height: 44px;
  border-radius: 6px;
  border: 1px solid ${colors[color]};
  background-color: ${inverted ? colors.WHITE : colors[color]};
  white-space: pre;
  text-transform: capitalize;
  font-size: 16px;
  color: ${inverted ? colors[color] : colors.WHITE};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    border-color: ${colors.GREY};
    background-color: ${colors.GREY};
    cursor: not-allowed;
  }
`;
