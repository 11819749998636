/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { iconStyles } from './Icon.styles';

const Icon = ({ styles, name }) => (
  <i css={[iconStyles, styles]} className={`ri-${name}`} />
);

Icon.propTypes = {
  styles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  styles: css``,
};

export default Icon;
