/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import {
  Modal,
  Heading,
  Button,
  Icon,
  Text,
} from '../../../general';
import { PlainCard } from '../../../cards';
import { domainNameParser } from '../../../../helpers';
import {
  cardStyles,
  cardHeaderStyles,
  titleStyles,
  closeButtonStyles,
  closeIconStyles,
  cardBodyStyles,
  descriptionStyles,
  buttonStyles,
} from './SuccessModal.styles';

const SuccessModal = ({
  resetPasswordStatus,
  setResetPasswordStatus,
}) => {
  const [searchParams] = useSearchParams();

  const handleCloseModal = () => {
    setResetPasswordStatus((prevState) => ({
      ...prevState,
      isSuccessModalShown: false,
    }));
  };

  const handleRedirect = () => {
    const redirect = searchParams.get('redirect');
    window.location.replace(`${domainNameParser(redirect)}/login`);
    handleCloseModal();
  };

  return (
    <Modal isModalShown={resetPasswordStatus.isSuccessModalShown}>
      <PlainCard styles={cardStyles}>
        <div css={cardHeaderStyles}>
          <Heading type="h3" styles={titleStyles}>
            password changed
          </Heading>
          <button
            type="button"
            css={closeButtonStyles}
            onClick={handleCloseModal}
          >
            <Icon name="close-line" styles={closeIconStyles} />
          </button>
        </div>
        <div css={cardBodyStyles}>
          <Text styles={descriptionStyles}>
            you have successfully changed your password, please login with a new
            one.
          </Text>
          <Button
            type="button"
            styles={buttonStyles}
            size="FULL"
            onClick={handleRedirect}
          >
            login
          </Button>
        </div>
      </PlainCard>
    </Modal>
  );
};

SuccessModal.propTypes = {
  resetPasswordStatus: PropTypes.shape({
    isPasswordMatch: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setResetPasswordStatus: PropTypes.func.isRequired,
};

export default SuccessModal;
