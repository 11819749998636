/* eslint-disable react/button-has-type */
/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { buttonStyles } from './Button.styles';

const Button = ({
  type,
  size,
  color,
  styles,
  children,
  onClick,
  inverted,
  disabled,
}) => {
  let width = 100;

  if (size === 'M') width = 150;
  if (size === 'L') width = 200;
  if (size === 'XL') width = 250;
  if (size === 'FULL') width = 'full';

  return (
    <button
      type={type}
      css={[buttonStyles(width, color, inverted), styles]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['S', 'M', 'L', 'XL', 'FULL']).isRequired,
  styles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  styles: css``,
  color: 'PRIMARY_YELLOWISH_ORANGE',
  onClick: () => {},
  inverted: false,
  disabled: false,
};

export default Button;
