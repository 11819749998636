import {
  AccountActivationPage,
  ChangeEmailPage,
  EmailActivationPage,
  HomePage,
  ResetPasswordPage,
} from '../pages';

const routes = [
  {
    path: '/account-activation',
    component: <AccountActivationPage />,
  },
  {
    path: '/change-email',
    component: <ChangeEmailPage />,
  },
  {
    path: '/email-activation',
    component: <EmailActivationPage />,
  },
  {
    path: '/',
    component: <HomePage />,
  },
  {
    path: '/reset-password',
    component: <ResetPasswordPage />,
  },
];

export default routes;
