/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/react';

import Icon from '../Icon/Icon';
import {
  inputContainerStyles,
  iconStyles,
  inputStyles,
  showAndHideButtonStyles,
  eyeIconStyles,
} from './InputPassword.styles';

const InputPassword = ({
  containerStyles,
  name,
  value,
  onChange,
  placeholder,
  readOnly,
  required,
  isError,
}) => {
  const [isPasswordShown, setPasswordStatus] = useState(false);

  const handleTogglePassword = () => {
    setPasswordStatus((prevState) => !prevState);
  };

  return (
    <div css={[inputContainerStyles, containerStyles]}>
      <Icon name="lock-line" styles={iconStyles(isError)} />
      <input
        id={name}
        type={isPasswordShown ? 'text' : 'password'}
        css={inputStyles}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
      />
      <button
        type="button"
        tabIndex={-1}
        css={showAndHideButtonStyles}
        onClick={handleTogglePassword}
      >
        <Icon
          name={`${isPasswordShown ? 'eye-off' : 'eye'}-fill`}
          styles={eyeIconStyles}
        />
      </button>
    </div>
  );
};

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  containerStyles: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  isError: PropTypes.bool,
};

InputPassword.defaultProps = {
  containerStyles: css``,
  readOnly: false,
  required: false,
  isError: false,
};

export default InputPassword;
