import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import routeConfig from './configs/routeConfig';

const App = () => (
  <Router>
    <Routes>
      {routeConfig.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
    </Routes>
  </Router>
);

export default App;
