/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import colors from '../../../configs/colors';
import { shortTextStyles } from './ShortText.styles';

const ShortText = ({
  children,
  styles,
  textTransform,
  color,
}) => (
  <span css={[shortTextStyles({ textTransform, color }), styles]}>
    {children}
  </span>
);

ShortText.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  textTransform: PropTypes.oneOf([
    'none',
    'lowercase',
    'capitalize',
    'uppercase',
  ]),
  color: PropTypes.string,
};

ShortText.defaultProps = {
  styles: css``,
  textTransform: 'none',
  color: colors.PRIMARY_BLACK,
};

export default ShortText;
