/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import SuccessModal from './SuccessModal/SuccessModal';
import backgroundImage from '../../../assets/images/pages/reset-password/background.jpg';
import companyLogo from '../../../assets/svg/logo-dark.svg';
import {
  Heading,
  Icon,
  InputPassword,
  Button,
  ShortText,
  Text,
} from '../../general';
import {
  containerStyles,
  backgroundImageStyles,
  formContainerStyles,
  titleStyles,
  iconStyles,
  formStyles,
  formTitleStyles,
  credentialInputContainerStyles,
  errorMessageContainerStyles,
  errorMessageStyles,
  submitButtonStyles,
  serverErrorMessageStyles,
} from './AccountActivation.styles';

const AccountActivation = ({
  isTokenValid,
  formData,
  accountActivationStatus,
  setAccountActivationStatus,
  errorMessage,
  handleChange,
  handleSubmit,
}) => (
  <div css={containerStyles}>
    <div css={backgroundImageStyles(backgroundImage)} />
    {!isTokenValid ? (
      <div css={formContainerStyles}>
        <Heading type="h3" styles={formTitleStyles}>
          Action Token is not valid or expired
        </Heading>
      </div>
    ) : (
      <div css={formContainerStyles}>
        <img src={companyLogo} alt="hacktiv8-logo" />
        <Heading type="h1" styles={titleStyles}>
          <Icon name="shield-keyhole-fill" styles={iconStyles} />
        &nbsp;Setup Your New Account
        </Heading>
        <form css={formStyles} onSubmit={handleSubmit}>
          <Heading type="h3" styles={formTitleStyles}>
            Activate your account by setting a new password
          </Heading>
          <InputPassword
            containerStyles={credentialInputContainerStyles}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="password"
            isError={accountActivationStatus.isError}
          />
          <InputPassword
            containerStyles={credentialInputContainerStyles}
            name="confirmationPassword"
            value={formData.confirmationPassword}
            onChange={handleChange}
            placeholder="confirm password"
            isError={accountActivationStatus.isError}
          />
          <div css={errorMessageContainerStyles}>
            {!accountActivationStatus.isPasswordMatch && (
              <ShortText styles={errorMessageStyles}>
                password not match
              </ShortText>
            )}
          </div>
          <Button
            type="submit"
            styles={submitButtonStyles}
            size="FULL"
            color="PRIMARY_BLUE"
          >
            {accountActivationStatus.isSubmitting
              ? 'submitting...'
              : 'set new password'}
          </Button>
        </form>
        <Text styles={serverErrorMessageStyles}>{errorMessage}</Text>
      </div>
    )}
    <SuccessModal
      accountActivationStatus={accountActivationStatus}
      setAccountActivationStatus={setAccountActivationStatus}
    />
  </div>
);

AccountActivation.propTypes = {
  isTokenValid: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    password: PropTypes.string,
    confirmationPassword: PropTypes.string,
  }).isRequired,
  accountActivationStatus: PropTypes.shape({
    isPasswordMatch: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setAccountActivationStatus: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AccountActivation;
