/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import colors from '../../../configs/colors';
import { headingStyles } from './Heading.styles';

const Heading = ({
  type,
  children,
  styles,
  textTransform,
  fontWeight,
  color,
}) => {
  switch (type) {
    case 'h1':
      return (
        <h1
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h2>
      );

    case 'h3':
      return (
        <h3
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h3>
      );

    case 'h4':
      return (
        <h4
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h4>
      );

    case 'h5':
      return (
        <h5
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h6>
      );

    default:
      return (
        <h1
          css={[
            headingStyles({
              textTransform,
              fontWeight,
              color,
            }),
            styles,
          ]}
        >
          {children}
        </h1>
      );
  }
};

Heading.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  styles: PropTypes.shape({}),
  textTransform: PropTypes.oneOf([
    'none',
    'lowercase',
    'capitalize',
    'uppercase',
  ]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};

Heading.defaultProps = {
  type: 'h1',
  styles: css``,
  textTransform: 'capitalize',
  fontWeight: 400,
  color: colors.PRIMARY_BLACK,
};

export default Heading;
