/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/react';

import { linkStyles } from './Link.styles';

const Link = ({ to, styles, children }) => (
  <NavLink to={to} css={[linkStyles, styles]}>
    {children}
  </NavLink>
);

Link.propTypes = {
  to: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  styles: css``,
};

export default Link;
