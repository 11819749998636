import { css } from '@emotion/react';

import colors from '../../../configs/colors';

export const inputContainerStyles = css`
  position: relative;
  width: 100%;
`;

export const iconStyles = (isError) => css`
  position: absolute;
  top: 50%;
  left: 18px;
  margin: -6.5px 0 0 0;
  font-size: 13px;
  color: ${isError ? colors.DANGER_RED : colors.PRIMARY_BLUE};
`;

export const inputStyles = css`
  width: 100%;
  height: 48px;
  padding: 16px 49px 16px 40px;
  border: 1px solid ${colors.GREY};
  border-radius: 8px;
  background-color: ${colors.BACKGROUND_WHITE};
  font-size: 16px;
  color: ${colors.BLACK};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: border-color 0.2s ease-in-out;

  &::placeholder {
    text-transform: capitalize;
    color: ${colors.DARKER_GREY};
  }

  &:hover {
    border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  }

  &:focus {
    border-color: ${colors.PRIMARY_YELLOWISH_ORANGE};
  }

  &:read-only {
    background-color: ${colors.GREY};
    cursor: not-allowed;

    &::placeholder {
      color: ${colors.WHITE};
    }
  }
`;

export const showAndHideButtonStyles = css`
  position: absolute;
  top: 50%;
  right: 18px;
  margin: -11px 0 0 0;
  padding: 0;
  background-color: ${colors.TRANSPARENT};
  border: unset;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const eyeIconStyles = css`
  font-size: 22px;
  color: ${colors.GREY};
`;
