/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { primaryCardStyles } from './PlainCard.styles';

const PlainCard = ({ styles, children }) => (
  <div css={[primaryCardStyles, styles]}>{children}</div>
);

PlainCard.propTypes = {
  styles: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

PlainCard.defaultProps = {
  styles: css``,
};

export default PlainCard;
