/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { modalStyles, overlayStyles, modalBodyStyles } from './Modal.styles';

const Modal = ({ isModalShown, children }) => (
  <div css={modalStyles(isModalShown)}>
    <div css={overlayStyles} />
    <div css={modalBodyStyles}>
      {children}
    </div>
  </div>
);

Modal.propTypes = {
  isModalShown: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
