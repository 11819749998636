const domainNameParser = (key) => {
  switch (key) {
    case 'company-portal':
      return `${process.env.REACT_APP_COMPANY_PORTAL_URL}/en`;
    case 'seeker-portal':
      return process.env.REACT_APP_SEEKER_PORTAL_URL;
    default:
      return `${process.env.REACT_APP_COMPANY_PORTAL_URL}/en`;
  }
};

export default domainNameParser;
