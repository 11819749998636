import { css } from '@emotion/react';

export const textStyles = ({ textTransform, color }) => css`
  text-transform: ${textTransform};
  font-size: 14px;
  color: ${color};

  &:first-letter {
    text-transform: uppercase;
  }
`;
