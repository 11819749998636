/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import SuccessModal from './SuccessModal/SuccessModal';
import {
  Heading,
  Icon,
  InputPassword,
  Button,
  ShortText,
  Text,
} from '../../general';
import {
  containerStyles,
  backgroundImageStyles,
  formContainerStyles,
  titleStyles,
  iconStyles,
  formStyles,
  formTitleStyles,
  credentialInputContainerStyles,
  errorMessageContainerStyles,
  errorMessageStyles,
  submitButtonStyles,
  serverErrorMessageStyles,
} from './ResetPassword.styles';
import backgroundImage from '../../../assets/images/pages/reset-password/background.jpg';
import companyLogo from '../../../assets/svg/logo-dark.svg';

const ResetPassword = ({
  formData,
  resetPasswordStatus,
  setResetPasswordStatus,
  errorMessage,
  handleChange,
  handleSubmit,
}) => (
  <div css={containerStyles}>
    <div css={backgroundImageStyles(backgroundImage)} />
    <div css={formContainerStyles}>
      <img src={companyLogo} alt="hacktiv8-logo" />
      <Heading type="h1" styles={titleStyles}>
        <Icon name="shield-keyhole-fill" styles={iconStyles} />
        &nbsp;Set a New Password
      </Heading>
      <form css={formStyles} onSubmit={handleSubmit}>
        <Heading type="h3" styles={formTitleStyles}>
          Please Create a Secure Password
        </Heading>
        <InputPassword
          containerStyles={credentialInputContainerStyles}
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="password"
          isError={resetPasswordStatus.isError}
        />
        <InputPassword
          containerStyles={credentialInputContainerStyles}
          name="confirmationPassword"
          value={formData.confirmationPassword}
          onChange={handleChange}
          placeholder="confirm password"
          isError={resetPasswordStatus.isError}
        />
        <div css={errorMessageContainerStyles}>
          {!resetPasswordStatus.isPasswordMatch && (
            <ShortText styles={errorMessageStyles}>
              password not match
            </ShortText>
          )}
        </div>
        <Button
          type="submit"
          styles={submitButtonStyles}
          size="FULL"
          color="PRIMARY_BLUE"
        >
          {resetPasswordStatus.isSubmitting
            ? 'submitting...'
            : 'set new password'}
        </Button>
      </form>
      <Text styles={serverErrorMessageStyles}>{errorMessage}</Text>
    </div>
    <SuccessModal
      resetPasswordStatus={resetPasswordStatus}
      setResetPasswordStatus={setResetPasswordStatus}
    />
  </div>
);

ResetPassword.propTypes = {
  formData: PropTypes.shape({
    password: PropTypes.string,
    confirmationPassword: PropTypes.string,
  }).isRequired,
  resetPasswordStatus: PropTypes.shape({
    isPasswordMatch: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccessModalShown: PropTypes.bool,
  }).isRequired,
  setResetPasswordStatus: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ResetPassword;
